<!--
  他们都是表单，都能对表单就行修改
  + 发布资讯
  + 编辑资讯
 -->
<template>
  <div class="detail">
    <detail-header title="新增资讯" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <el-form
          ref="elForm"
          :model="formData"
          :rules="rules"
          size="small"
          label-width="140px"
          label-position="left"
        >
          <el-form-item label="所属栏目" prop="sysColumnId">
            <el-select
              v-model="formData.sysColumnId"
              placeholder="请选择栏目"
              clearable
              style="width: 380px"
            >
              <el-option
                v-for="(item, index) in tabList"
                :key="index"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item
            label="适用地区"
            prop="province">
            <el-select
              v-model="formData.province"
              placeholder="请选择适用地区"
              filterable
              clearable
              style="width: 380px;">
              <el-option
                v-for="item in provinceList"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="资讯标题" prop="title">
            <el-input maxlength="50" show-word-limit
              v-model="formData.title"
              placeholder="请输入资讯标题"
              clearable
              style="width: 380px"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="资讯内容" prop="content">
            <div class="editor_wrapper">
              <editor
                api-key="no-api-key"
                :init="editConfig"
                v-model="formData.content"
              >
              </editor>
            </div>
          </el-form-item>
          <el-form-item size="large">
            <el-button
              type="primary"
              size="small"
              :loading="btnLoading"
              @click="submitForm"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * apik: sflyjyj5m62qkh34c1xd63ivtufokyz9x9g37nt332wnrcy2 （通过zhaosi@yoyohr.com申请的）
 * inymce主程序及自带的大部分插件均提供社区开源版，可免费使用且可商用。tinymce的主要盈利模式为【付费插件及拓展服务】
 * 有关付费项目的列表，请参考：https://www.tiny.cloud/pricing
 */
import Editor from '@tinymce/tinymce-vue'
import editConfig from '@/utils/import-tinymce'
import * as api from '@/api'
export default {
  data () {
    return {
      // 页面loadding
      loading: false,
      // 提交按钮loading
      btnLoading: false,
      // 栏目列表
      tabList: [],
      // 编辑器配置
      editConfig: editConfig,
      // 表单
      formData: {
        sysColumnId: '',
        title: '',
        // 省
        // province: '',
        // 富文本内容
        content: ''
      },
      rules: {
        sysColumnId: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        // province: [
        //   { required: true, message: '请选择适用地区', trigger: 'change' }
        // ],
        title: [{ required: true, message: '请输入资讯标题', trigger: 'blur' }],
        content: [
          { required: true, message: '请输入资讯内容', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    // 资讯id
    id: {
      type: String,
      default: ''
    }
  },
  components: {
    editor: Editor
  },
  created () {
    // 获取栏目选项
    this.getInfoColumn()
    // id 存在的话就是进行编辑、需要获取详情
    // if (this.id) {
    //   this.getInfoDetail()
    // }
  },
  methods: {
    // 获取栏目列表
    getInfoColumn () {
      api
        .getInfoColumn()
        .then((res) => {
          if (res.data.code === 0) {
            console.log('获取栏目列表', res.data)
            this.tabList = res.data.data.list
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
          this.$message.error('请求出错')
        })
        .finally(() => {})
    },
    // 获取栏目列表
    getInfoDetail () {
      api
        .getInfoDetail(this.id)
        .then((res) => {
          if (res.data.code === 0) {
            console.log('获取资讯', res.data)
            this.formData = this.$clone(res.data.data)
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
          this.$message.error('请求出错')
        })
        .finally(() => {})
    },
    // 提交表单
    submitForm () {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          if (this.id) {
            this.updateInfo()
          } else {
            this.addInfo()
          }
        } else {
          return false
        }
      })
    },
    // 添加资讯
    addInfo () {
      this.btnLoading = true
      api
        .addInfo({
          ...this.formData
        })
        .then((res) => {
          if (res.data.code === 0) {
            console.log('添加资讯成功', res.data.data)
            this.$message.success('添加成功！')
            this.$router.push('/info-manage')
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
          this.$message.error('请求出错')
        })
        .finally(() => {
          this.btnLoading = false
        })
    },
    // 编辑资讯
    updateInfo () {
      this.btnLoading = true
      api
        .updateInfo(this.id, {
          ...this.formData
        })
        .then((res) => {
          if (res.data.code === 0) {
            console.log('编辑资讯成功', res.data.data)
            this.$message.success('编辑成功！')
            this.$router.push('/info-manage')
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
          this.$message.error('请求出错')
        })
        .finally(() => {
          this.btnLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 1140px;
      margin: 0 auto;

      // .editor_wrapper {

      // }
    }
  }
}
</style>
